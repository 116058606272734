export default [
  {
    key: 'locationId',
    label: 'field.location',
    type: 'asyn-multi-selection',
    operator: 'in',
    repository: 'location',
    selectionKey: 'id',
    selectionLabel: 'name',
    xl: 3,
  },
  {
    key: 'name',
    label: 'field.name',
    type: 'text',
    operator: '%',
  },
  {
    key: 'isEnable',
    label: 'field.status',
    rules: 'required',
    type: 'radio',
    cast: 'boolean',
    options: [
      { text: 'field.active', value: '1' },
      { text: 'field.inactive', value: '0' },
    ],
    lg: 'auto',
  },
]
